<template>
  <div :class="$style.policy">
    <div :class="$style.wrapper">
      <a :href="$configData.addevents_link + slug" target="_blank">
        <el-button size="small" type="primary">Просмотреть</el-button>
      </a>
      <el-button
        size="small"
        type="primary"
        :class="$style.button"
        @click="submitForm()"
      >
        Сохранить контент
      </el-button>
    </div>
    <el-form
      :model="form"
      ref="form"
      :rules="rules"
      label-position="left"
      label-width="12.5rem"
      :class="$style.form"
    >
      <el-form-item label="Название" prop="title">
        <el-input v-model="form.title" />
      </el-form-item>

      <el-form-item label="Имя в url">
        <el-input v-model="slug" disabled />
      </el-form-item>

      <el-form-item v-if="form.description" label="Описание">
        <TextEditor :value.sync="form.description" :height="300" />
      </el-form-item>

      <ContentSections :sections.sync="form.sections" />

      <SeoBlock
        :title.sync="form.seo.title"
        :description.sync="form.seo.description"
        :og-title.sync="form.seo.ogTitle"
        :og-description.sync="form.seo.ogDescription"
        :og-image.sync="form.seo.ogImage"
        :class="$style.seo"
      />
    </el-form>
  </div>
</template>

<script>
import TextEditor from '@/components/atoms/TextEditor.vue'
import SeoBlock from '@/components/moleculs/SEO.vue'
import ContentSections from '@/components/organisms/ContentSections.vue'
import delivery from '@/delivery'

import { CONTENT_BLOCK_TYPES } from '@/constants/content/blocks'

export default {
  components: {
    SeoBlock,
    ContentSections,
    TextEditor,
  },
  CONTENT_BLOCK_TYPES,

  data() {
    return {
      slug: '',
      form: {
        title: '',
        description: '',
        sections: [],
        seo: {
          title: '',
          description: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: '',
        },
      },
      rules: {
        title: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        seo: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogTitle: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogDescription: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogImage: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'change',
            },
          ],
        },
      },
    }
  },

  created() {
    this.getContent()
  },

  methods: {
    initAboutContent(contentArray) {
      const defaultContent = [
        { title: '', description: '', image: '' },
        { title: '', description: '' },
        { title: '', description: '', image: '' },
        { title: '', description: '' },
      ]

      if (contentArray.length >= 4) {
        return contentArray.slice(0, 4)
      }

      return contentArray.concat(defaultContent.slice(contentArray.length, 4))
    },

    async getContent() {
      const loading = this.$loading({
        lock: true,
      })

      const slug = this.$route.params.id == 'main' ? '' : this.$route.params.id

      const { value, error } =
        await delivery.ContentServiceCore.PagesActions.get(slug, 'addevent')
      loading.close()

      if (error) return

      this.slug = value.slug
      this.form = { ...value.content, description: value.content.text ?? '' }

      if (this.slug === 'rent-terms') {
        let list = {}
        let sections = {}
        if (value.content.list) {
          sections = { type: 'LIST', content: value.content.list }
        }

        if (value.content.sections) {
          list = { type: 'ACCORDION', content: value.content.sections }
        }
        this.form = { ...value.content, sections: [list, sections] }
      }
      
      if (this.slug === '') {
  let sections = []
  if (value.content.sections && value.content.sections.length > 0) {
    sections = value.content.sections
    const stepsSectionExists = sections.some(
      (section) => section.type === CONTENT_BLOCK_TYPES.STEPS_BLOCK,
    )

    if (!stepsSectionExists) {
      sections.push({
        type: CONTENT_BLOCK_TYPES.STEPS_BLOCK,
        data: {
          steps: [],
          title: '',
          subtitle: '',
          buttonText: '',
          buttonStyle: 'blue', 
        },
      })
    }
  } else {
    sections = [
      {
        type: CONTENT_BLOCK_TYPES.STEPS_BLOCK,
        data: {
          steps: [],
          title: '',
          subtitle: '',
          buttonText: '',
          buttonStyle: 'blue', 
        },
      },
    ]
  }
  this.form = { ...value.content, sections }
}

      if (this.slug === 'about') {
        let sections = []
        if (value.content.sections && value.content.sections.length > 0) {
          sections = value.content.sections

          const aboutSection = sections.find(
            (section) => section.type === CONTENT_BLOCK_TYPES.ABOUT_BLOCK,
          )
          const reviewsSectionExists = sections.some(
            (section) => section.type === CONTENT_BLOCK_TYPES.REVIEWS_BLOCK,
          )

          if (aboutSection) {
            aboutSection.data.content = this.initAboutContent(
              aboutSection.data.content,
            )
          } else {
            sections.unshift({
              type: CONTENT_BLOCK_TYPES.ABOUT_BLOCK,
              data: {
                content: this.initAboutContent([]),
              },
            })
          }

          if (!reviewsSectionExists) {
            sections.push({
              type: CONTENT_BLOCK_TYPES.REVIEWS_BLOCK,
              data: {
                reviews: [],
                title: '',
              },
            })
          }
        } else {
          sections = [
            {
              type: CONTENT_BLOCK_TYPES.ABOUT_BLOCK,
              data: {
                content: this.initAboutContent([]),
              },
            },
            {
              type: CONTENT_BLOCK_TYPES.REVIEWS_BLOCK,
              data: {
                reviews: [],
                title: '',
              },
            },
          ]
        }
        this.form = { ...value.content, sections }
      }
    },

    async submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })

          const data = {
            ...this.form,
          }

          if (this?.form?.description) {
            data.text = this?.form?.description
          }

          const slug =
            this.$route.params.id == 'main' ? '' : this.$route.params.id

          if (slug == 'rent-terms') {
            const a = this.form?.sections?.find((x) => {
              if (x.type === CONTENT_BLOCK_TYPES.ACCORDION_BLOCK) return true
              return false
            })
            data.sections = a.content

            const b = this.form?.sections?.find((x) => {
              if (x.type === CONTENT_BLOCK_TYPES.LIST_BLOCK) return true
              return false
            })
            data.list = b.content
          }

          const result = await delivery.ContentServiceCore.PagesActions.update(
            slug,
            'addevent',
            data,
          )
          loading.close()

          if (result.error) {
            this.$message({
              message: 'Ошибка редактирования страницы',
              type: 'error',
            })
            return
          }
          this.$message({
            message: 'Страница успешно отредактирована',
            type: 'success',
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" module>
.policy {
  padding: 1rem;
  .wrapper {
    @include stickyWrapper;
    .button {
      margin-left: 1rem;
    }
  }
  .form {
    padding-top: 2rem;
  }
  .seo {
    padding-top: 2rem;
  }
}
</style>
