<template>
  <div :class="$style.mainBanner">
    <AddeventContentBlock
      title="Главный баннер"
      :content="{ data: mainBannerSection.data.content }"
      :add-description="false"
      :add-reverse="false"
    />

    <h3>Блок Кнопка</h3>
    <el-form-item label="Заголовок">
      <el-input v-model="mainBannerSection.data.button.title" />
    </el-form-item>
    <el-form-item label="Ссылка">
      <el-input v-model="mainBannerSection.data.button.link" />
    </el-form-item>

    <h3>Блок Достижения</h3>
    <Container
      @drop="onDropAchievements"
      :non-drag-area-selector="'.drag-disabled'"
    >
      <Draggable
        v-for="(achievement, index) in mainBannerSection.data.achievement"
        :key="index"
      >
        <div :class="$style.achievements">
          <div :class="$style.achievement">
            <el-form-item label="Заголовок">
              <el-input v-model="achievement.title" class="drag-disabled" />
            </el-form-item>
            <el-form-item label="Описание">
              <el-input
                v-model="achievement.description"
                class="drag-disabled"
              />
            </el-form-item>
          </div>
          <div :class="$style.controls">
            <div :class="$style.removeButton">
              <el-button
                type="danger"
                plain
                circle
                icon="el-icon-delete"
                :class="($style.remove, 'drag-disabled')"
                @click="removeAchievement(index)"
              />
            </div>
            <div :class="$style.dragIcon">
              <Icon name="draggable" :class="$style.icon" />
            </div>
          </div>
        </div>
      </Draggable>
    </Container>
    <el-button
      size="small"
      type="primary"
      :class="$style.button"
      @click="addAchievementElem()"
      :disabled="mainBannerSection?.data?.achievement?.length > 1"
      >Добавить
    </el-button>

    <AddeventIconBlock
      title="Блок Преимущества"
      :content="mainBannerSection.data.advantages"
      :icons="iconOptions"
      @input="mainBannerSection.data.advantages = $event"
    />
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon'
import AddeventContentBlock from '@/components/moleculs/AddeventContentBlock.vue'
import AddeventIconBlock from '@/components/moleculs/AddeventIconBlock.vue'

export default {
  components: {
    Icon,
    AddeventContentBlock,
    AddeventIconBlock,
  },

  props: {
    mainBannerSection: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      iconOptions: [
        'advantage-person',
        'advantage-alarm',
        'advantage-diamond',
        'advantage-delivery',
      ],
    }
  },

  methods: {
    addAchievementElem() {
      if (this.mainBannerSection?.data?.achievement?.length > 1) {
        return
      }
      this.mainBannerSection.data.achievement.push({
        title: '',
        description: '',
      })
    },
    removeAchievement(index) {
      this.mainBannerSection.data.achievement.splice(index, 1)
    },

    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },
    onDropAchievements(dropResult) {
      this.mainBannerSection.data.achievement = this.applyDrag(
        this.mainBannerSection.data.achievement,
        dropResult,
      )
    },
  },
}
</script>

<style lang="scss" module>
.button {
  margin: 1rem 0;
}

.mainBanner {
  display: block;
  padding: 1rem 0;

  .achievements {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    border-bottom: 1px solid $black;

    .achievement {
      width: 100%;
      padding: 1rem 0;
    }

    .controls {
      margin-top: 30px;
      display: flex;
      gap: 1rem;
    }
  }

  .icon {
    height: 2.5rem;
    width: 2.5rem;
  }
}
</style>
