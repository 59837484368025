var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.contentBlock},[_c('h2',[_vm._v("Контент")]),_vm._l((_vm.content.data.content),function(item,index){return _c('div',{key:index},[_c('el-form-item',{attrs:{"label":"Имя"}},[_c('el-input',{staticClass:"drag-disabled",attrs:{"placeholder":"Введите имя","maxlength":"60","show-word-limit":""},model:{value:(item.title),callback:function ($$v) {_vm.$set(item, "title", $$v)},expression:"item.title"}})],1),_c('el-form-item',{attrs:{"label":"Описание"}},[_c('TextEditor',{attrs:{"value":item.description,"height":300},on:{"update:value":function($event){return _vm.$set(item, "description", $event)}}})],1),_c('el-form-item',{attrs:{"label":"Реверсировать содержимое"}},[_c('el-checkbox',{attrs:{"label":item.isReverse ? 'Да' : 'Нет',"size":"large"},model:{value:(item.isReverse),callback:function ($$v) {_vm.$set(item, "isReverse", $$v)},expression:"item.isReverse"}})],1),(_vm.shouldShowUploader(index))?_c('el-form-item',{attrs:{"label":"Фото"}},[_c('Uploader',{attrs:{"without-caption":"","limit":1,"files":item.image
            ? [
                {
                  original: item.image,
                },
              ]
            : []},on:{"upload":function($event){return _vm.uploadImages($event, index)}}})],1):_vm._e()],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }