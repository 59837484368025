<template>
  <div>
    <div v-for="(section, index) in sections" :key="index">
      <AddEventMainBanner
        v-if="section.type === $options.CONTENT_BLOCK_TYPES.MAIN_BANNER"
        :mainBannerSection="section"
      />
      <AddeventContentBlock
        v-if="section.type === $options.CONTENT_BLOCK_TYPES.CONTENT_BLOCK"
        title="Блок репутация"
        :content="section"
      />
      <AddEventAboutBlock
        v-if="section.type == $options.CONTENT_BLOCK_TYPES.ABOUT_BLOCK"
        :content="section"
      />
      <AddReviewsBlock
        v-if="section.type == $options.CONTENT_BLOCK_TYPES.REVIEWS_BLOCK"
        :review="section"
      />
      <AddeventListBlock
        v-if="section.type == $options.CONTENT_BLOCK_TYPES.LIST_BLOCK"
        :section="section"
      />
      <AddeventAccordionBlock
        v-if="section.type == $options.CONTENT_BLOCK_TYPES.ACCORDION_BLOCK"
        :accordion="section"
      />
      <AddeventStepsBlock
      v-if="section.type === $options.CONTENT_BLOCK_TYPES.STEPS_BLOCK"
      :section="section"
    />

    </div>
  </div>
</template>

<script>
import AddEventAboutBlock from '@/components/moleculs/AddEventAboutBlock.vue'
import AddEventMainBanner from '@/components/moleculs/AddEventMainBanner.vue'
import AddReviewsBlock from '@/components/moleculs/AddReviewsBlock.vue'
import AddeventAccordionBlock from '@/components/moleculs/AddeventAccordionBlock.vue'
import AddeventContentBlock from '@/components/moleculs/AddeventContentBlock.vue'
import AddeventListBlock from '@/components/moleculs/AddeventListBlock.vue'
import AddeventStepsBlock from '@/components/moleculs/AddeventStepsBlock.vue'

import { CONTENT_BLOCK_TYPES } from '@/constants/content/blocks'

export default {
  CONTENT_BLOCK_TYPES,
  components: {
    AddEventMainBanner,
    AddeventContentBlock,
    AddeventAccordionBlock,
    AddeventListBlock,
    AddEventAboutBlock,
    AddReviewsBlock,
    AddeventStepsBlock
  },
  props: {
    sections: {
      type: Array,
      default: () => [],
    },
  },
}
</script>
