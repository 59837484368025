<template>
  <div :class="$style.contentBlock">
    <h2>Контент</h2>

    <div v-for="(item, index) in content.data.content" :key="index">
      <el-form-item label="Имя">
        <el-input
          v-model="item.title"
          placeholder="Введите имя"
          class="drag-disabled"
          maxlength="60"
          show-word-limit
        />
      </el-form-item>

      <el-form-item label="Описание">
        <TextEditor :value.sync="item.description" :height="300" />
      </el-form-item>

      <el-form-item label="Реверсировать содержимое">
        <el-checkbox
          v-model="item.isReverse"
          :label="item.isReverse ? 'Да' : 'Нет'"
          size="large"
        />
      </el-form-item>

      <el-form-item label="Фото" v-if="shouldShowUploader(index)">
        <Uploader
          without-caption
          :limit="1"
          :files="
            item.image
              ? [
                  {
                    original: item.image,
                  },
                ]
              : []
          "
          @upload="uploadImages($event, index)"
        />
      </el-form-item>
    </div>
  </div>
</template>

<script>
import TextEditor from '@/components/atoms/TextEditor.vue'
import Uploader from '@/components/moleculs/AddwineUploader.vue'

export default {
  components: { Uploader, TextEditor },

  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },

  methods: {
    shouldShowUploader(index) {
      return index % 2 === 0
    },

    uploadImages(images, index) {
      this.content.data.content[index].image = images.length
        ? images[0].original
        : ''
    },
  },
}
</script>

<style lang="scss" module>
.contentBlock {
  padding: 1rem 0;

  h2 {
    padding-bottom: 2rem;
  }
}
</style>
