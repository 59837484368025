<template>
  <div :class="$style.reviewsBlock">
    <div :class="$style.reviewsTitle">
      <h3>Отзывы</h3>
      <el-input
        v-model="review.data.title"
        placeholder="Введите имя секции"
        class="drag-disabled"
      />
    </div>
    <Container @drop="onDropReviews" :non-drag-area-selector="'.drag-disabled'">
      <Draggable v-for="(review, index) in review.data.reviews" :key="index">
        <el-form
          :model="review"
          ref="reviewForm"
          label-width="120px"
          @submit.prevent
        >
          <div :class="$style.reviews">
            <div :class="$style.review">
              <el-form-item label="Имя" prop="name">
                <el-input
                  v-model="review.name"
                  placeholder="Введите имя"
                  class="drag-disabled"
                  maxlength="20"
                  show-word-limit
                />
              </el-form-item>
              <el-form-item label="Компания" prop="company">
                <el-input
                  placeholder="Введите название компании"
                  v-model="review.company"
                  class="drag-disabled"
                  maxlength="30"
                  show-word-limit
                />
              </el-form-item>
              <el-form-item label="Локация" prop="location">
                <el-input
                  v-model="review.location"
                  placeholder="Введите город"
                  class="drag-disabled"
                  maxlength="20"
                  show-word-limit
                />
              </el-form-item>
              <el-form-item label="Отзыв" prop="text">
                <el-input
                  type="textarea"
                  :rows="7"
                  placeholder="Введите отзыв"
                  v-model="review.text"
                  class="drag-disabled"
                  maxlength="600"
                  show-word-limit
                />
              </el-form-item>
              <el-form-item label="Дата" prop="date">
                <el-date-picker
                  v-model="review.date"
                  type="date"
                  :class="$style.date"
                  placeholder="Выберите дату"
                />
              </el-form-item>
            </div>
            <div :class="$style.controls">
              <div :class="$style.removeButton">
                <el-button
                  type="danger"
                  plain
                  circle
                  icon="el-icon-delete"
                  :class="($style.remove, 'drag-disabled')"
                  @click="removeReviews(index)"
                />
              </div>
              <div :class="$style.dragIcon">
                <Icon name="draggable" :class="$style.icon" />
              </div>
            </div>
          </div>
        </el-form>
      </Draggable>
    </Container>
    <el-button
      size="small"
      type="primary"
      :class="$style.button"
      @click="addReviewsElem()"
      :disabled="review?.data?.reviews?.length > 15"
      >Добавить
    </el-button>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon'

export default {
  components: {
    Icon,
  },

  props: {
    review: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    addReviewsElem() {
      if (this.review?.data?.reviews?.length > 15) {
        return
      }
      this.review.data.reviews.push({
        name: '',
        company: '',
        location: '',
        text: '',
        date: '',
      })
    },
    removeReviews(index) {
      this.review.data.reviews.splice(index, 1)
    },

    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },
    onDropReviews(dropResult) {
      this.review.data.reviews = this.applyDrag(
        this.review.data.reviews,
        dropResult,
      )
    },
  },
}
</script>

<style lang="scss" module>
.button {
  margin: 1rem 0;
}

.reviewsBlock {
  display: block;
  padding: 1rem 0;

  .reviewsTitle {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  .reviews {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    border-bottom: 1px solid $black;

    .review {
      width: 100%;
      padding: 1rem 0;
    }

    .controls {
      margin-top: 30px;
      display: flex;
      gap: 1rem;
    }
  }

  .icon {
    height: 2.5rem;
    width: 2.5rem;
  }
}
</style>
