<template>
  <div :class="$style.steps">
    <p :class="$style.title">Шаги</p>
    <el-form-item label="Заголовок" prop="images">
      <el-input
        v-model="section.content.title"
        :class="$style.input"
      ></el-input>
    </el-form-item>
    <template v-if="section?.content?.sections?.length">
      <div
        v-for="(step, i) in section?.content?.sections"
        :key="`step${i}`"
        :class="$style.step"
      >
        <el-form-item :label="`Шаг ${i + 1}`" prop="step">
          <TextEditor :value.sync="step.text" :height="300" />
        </el-form-item>
        <adw-icon
          name="close"
          :class="$style.close"
          @click.native="removeStep(i)"
        />
      </div>
    </template>
    <el-button
      size="small"
      type="primary"
      :disabled="section?.content?.sections?.length > 3"
      :class="$style.button"
      @click="addStep"
    >
      Добавить шаг
    </el-button>
  </div>
</template>

<script>
import TextEditor from '@/components/atoms/TextEditor.vue'

export default {
  components: {
    TextEditor,
  },
  props: {
    section: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    removeStep(index) {
      if (this?.section?.content?.sections?.length > 1) {
        this.section.content.sections =
          this.section.content.sections?.filter((_, i) => i !== index) ?? []
      }
    },
    addStep() {
      this.section.content.sections.push({
        text: '',
      })
    },
  },
}
</script>

<style lang="scss" module>
p {
  text-align: center;
  margin-bottom: 3rem;
  @include H200;
}
.steps {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .step {
    display: flex;
    align-items: center;
    gap: 1rem;
    > svg {
      cursor: pointer;
    }
  }
  .button {
    max-width: 10rem;
  }
}
</style>
