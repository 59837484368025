<template>
  <div :class="$style.policy">
    <div :class="$style.info">
      <p :class="$style.title">Блоки</p>
      <template v-if="accordion?.content?.length">
        <div
          v-for="(step, i) in accordion.content"
          :key="`step${i}`"
          :class="$style.block"
        >
          <div>
            <el-form-item label="Заголовок" prop="images">
              <el-input v-model="step.title" :class="$style.input"></el-input>
            </el-form-item>
            <el-form-item :label="`Шаг ${i + 1}`" prop="step">
              <TextEditor :value.sync="step.text" :height="300" />
            </el-form-item>
          </div>
          <adw-icon
            name="close"
            :class="$style.close"
            @click.native="removeBlock(i)"
          />
        </div>
      </template>
    </div>
    <el-button
      size="small"
      type="primary"
      :class="$style.button"
      @click="addBlock"
    >
      Добавить Блок
    </el-button>
  </div>
</template>

<script>
import TextEditor from '@/components/atoms/TextEditor.vue'

export default {
  components: {
    TextEditor,
  },
  props: {
    accordion: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    removeBlock(index) {
      this.accordion.content =
        this.accordion.content.filter((_, i) => i !== index) ?? []
    },
    addBlock() {
      this.accordion.content.push({
        title: '',
        text: '',
      })
    },
  },
}
</script>

<style lang="scss" module>
.policy {
  padding: 1rem;
  .wrapper {
    @include stickyWrapper;
    .button {
      margin-left: 1rem;
    }
  }
  h2,
  p {
    text-align: center;
    margin-bottom: 3rem;
  }
  p {
    @include H200;
  }
  .info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .block {
      display: flex;
      align-items: center;
      gap: 1rem;
      > svg {
        cursor: pointer;
      }
    }
    .button {
      max-width: 10rem;
    }
  }
}
</style>
