<template>
  <div :class="$style.contentBlock">
    <h2>{{ title }}</h2>
    <el-form-item label="Заголовок">
      <TextEditor :value.sync="content.data.title" :height="300" />
    </el-form-item>

    <el-form-item v-if="addDescription" label="Описание">
      <TextEditor :value.sync="content.data.description" :height="300" />
    </el-form-item>

    <el-form-item v-if="addReverse" label="Реверсировать содержимое">
      <el-checkbox
        v-model="content.data.isReverse"
        :label="content.data.isReverse ? 'Да' : 'Нет'"
        size="large"
      />
    </el-form-item>

    <el-form-item label="Фото">
      <Uploader
        without-caption
        :limit="1"
        :files="content.data.image ? [{ original: content.data.image }] : []"
        @upload="uploadImages"
      />
    </el-form-item>
  </div>
</template>

<script>
import Uploader from '@/components/moleculs/AddwineUploader.vue'
import TextEditor from '@/components/atoms/TextEditor.vue'

export default {
  components: { Uploader, TextEditor },

  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: Object,
      default: () => {},
    },
    addDescription: {
      type: Boolean,
      default: true,
    },
    addReverse: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    uploadImages(images) {
      this.content.data.image = images[0]?.original
    },
  },
}
</script>

<style lang="scss" module>
.contentBlock {
  padding: 1rem 0;

  h2 {
    padding-bottom: 2rem;
  }
}
</style>
