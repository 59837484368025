<template>
  <div :class="$style.stepsBlock">
    <div :class="$style.stepsTitle">
      <h3>Шаги для заказа</h3>
      <div :class="$style.inputGroup">
        <el-form-item :label="`Заголовок`" prop="title">
          <TextEditor :value.sync="section.data.title" :height="200" />
        </el-form-item>
      </div>
      <div :class="$style.inputGroup">
        <label for="subtitle">Подзаголовок:</label>
        <el-input
          v-model="section.data.subtitle"
          placeholder="Введите подзаголовок"
          class="drag-disabled"
        />
      </div>
      <div :class="$style.inputGroup">
        <label for="buttonText">Текст кнопки:</label>
        <el-input
          v-model="section.data.buttonText"
          placeholder="Введите текст кнопки"
          class="drag-disabled"
        />
      </div>
    </div>

    <div :class="$style.inputGroup">
      <label for="steps">Шаги для аренды:</label>
    </div>

    <div v-if="section.data.steps.length < 4" :class="$style.footer">
      <el-button size="small" type="primary" @click="addStep">
        Добавить шаг
      </el-button>
    </div>

    <div v-for="(step, index) in section.data.steps" :key="index" :class="$style.step">
      <el-form :model="step" ref="stepsForm" label-width="120px" @submit.prevent>
        <el-form-item :label="`Шаг ${index + 1}`" prop="text">
          <TextEditor :value.sync="step.description" :height="300" />
        </el-form-item>
        <el-button
          type="danger"
          plain
          circle
          icon="el-icon-delete"
          @click="removeStep(index)"
        />
      </el-form>
    </div>
  </div>
</template>

<script>
import TextEditor from '@/components/atoms/TextEditor.vue';

export default {
  components: {
    TextEditor,
  },
  props: {
    section: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    addStep() {
      if (this.section.data?.steps?.length >= 4) return;

      this.section.data.steps.push({
        description: '',
      });
    },
    removeStep(index) {
      this.section.data.steps.splice(index, 1);
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult;
      if (removedIndex === null && addedIndex === null) return arr;

      const result = [...arr];
      let itemToAdd = payload;

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0];
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd);
      }

      return result;
    },
    onDropSteps(dropResult) {
      this.section.data.steps = this.applyDrag(this.section.data.steps, dropResult);
    },
  },
};
</script>

<style lang="scss" module>
.stepsBlock {
  display: block;
  padding: 1rem 0;

  .stepsTitle {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .inputGroup {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .steps {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    border-bottom: 0.063rem solid $black;
    padding-bottom: 1rem;
  }

  .step {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border: 0.063rem solid $dark-gray;
    border-radius: 0.25rem;
    background-color: $smoky;
    width: 100%;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }
}
</style>