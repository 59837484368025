<template>
  <div :class="$style.advantages">
    <h3>{{ title }}</h3>
    <Container
      @drop="onDropAdvantages"
      :non-drag-area-selector="'.drag-disabled'"
    >
      <Draggable v-for="(advantage, index) in content" :key="index">
        <div :class="$style.achievements">
          <div :class="$style.achievement">
            <el-form-item label="Иконка" class="drag-disabled">
              <el-select
                v-model="advantage.iconName"
                filterable
                placeholder="Выберите иконку"
                style="width: 100%"
                :popper-class="$style.iconOptions"
              >
                <el-option
                  v-for="(item, index) in icons"
                  :key="index"
                  :label="item"
                  :value="item"
                >
                  <Icon :name="item" />
                  {{ item }}
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Заголовок">
              <el-input v-model="advantage.title" class="drag-disabled" />
            </el-form-item>
            <el-form-item label="Описание">
              <el-input v-model="advantage.description" class="drag-disabled" />
            </el-form-item>
          </div>
          <div :class="$style.controls">
            <div :class="$style.removeButton">
              <el-button
                type="danger"
                plain
                circle
                icon="el-icon-delete"
                :class="($style.remove, 'drag-disabled')"
                @click="removeAdvantage(index)"
              />
            </div>
            <div :class="$style.dragIcon">
              <Icon name="draggable" :class="$style.icon" />
            </div>
          </div>
        </div>
      </Draggable>
    </Container>
    <el-button
      size="small"
      type="primary"
      :class="$style.button"
      @click="addAdvantageElem()"
      :disabled="this.content.length > 3"
      >Добавить
    </el-button>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon'

export default {
  components: { Icon },
  props: {
    title: {
      type: String,
      default: '',
    },
    content: {
      type: Array,
      default: () => [],
    },
    icons: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    removeAdvantage(index) {
      this.content.splice(index, 1)
    },
    addAdvantageElem() {
      if (this.content.length > 3) {
        return
      }
      this.content.push({
        title: '',
        description: '',
        iconName: '',
      })
    },
    onDropAdvantages(dropResult) {
      this.$emit('input', this.applyDrag(this.content, dropResult))
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },
  },
}
</script>

<style lang="scss" module>
.iconOptions > div > div > ul {
  li {
    padding-top: 1rem;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      width: 3rem;
      height: 3rem;
      margin-right: 0.5rem;
      path {
        fill: $gray;
      }
    }
  }
}

.advantages {
  padding: 1rem 0;
}

.achievements {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  border-bottom: 1px solid $black;

  .achievement {
    width: 100%;
    padding: 1rem 0;
  }

  .controls {
    margin-top: 30px;
    display: flex;
    gap: 1rem;
  }

  .icon {
    height: 2.5rem;
    width: 2.5rem;
  }
}
</style>
